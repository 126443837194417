import logo from "./logo.svg";
import "./App.css";

import MainPage from "./components/mainPage";
import { Route, Routes, Link } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />}></Route>
    </Routes>
  );
}

export default App;
