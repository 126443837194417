import React, { useRef, useEffect, useState } from "react";
import { FullPage, Slide } from "react-full-page";
import { useMediaQuery } from "react-responsive";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Modal from "react-modal";

// 이미지들
import mrpLogoBlack from "./images/mrpLogoBlack.png";
import mrpLogoWhite from "./images/mrpLogoWhite.png";
import placeIcon from "./images/place_icon.png";
import phoneIcon from "./images/phone_icon.png";
import faxIcon from "./images/fax_icon.png";
import phliosophyIcon from "./images/phliosophy_icon.png";
import phliosophyMBIcon from "./images/philosophyMBIcon.svg";
import organizationIcon from "./images/organization_icon.png";
import organizationMBIcon from "./images/organizationMB.svg";
import contactUsIcon from "./images/contactUsIcon.png";
import mrpHoldingsGraphImg from "./images/organizationHoldings.png";
import CONSULTGraphImg from "./images/organizationCONSULT.png";
import EMGraphImg from "./images/organizationEM.png";
import PARTNERSGraphImg from "./images/organizationPARTNERS.png";
import softGraphImg from "./images/organizationSOFT.png";
import refreshIcon from "./images/refreshIcon.png";
import mainVideo from "../videos/mainVideo.mp4";
import dowmGreyIcon from "./images/downGrey.png";
import downWhtieIcon from "./images/downWhite.png";
import hamburgerMenu from "./images/hamburgerMenu.svg";
import contactUsMBIcon from "./images/contactUsMB.svg";
import xCloseBlack from "./images/xCloseBlack.svg";
import downVectorIcon from "./images/downVector.svg";
import philosophy1 from "./images/philosophy1.svg";
import philosophy2 from "./images/philosophy2.svg";
import philosophy3 from "./images/philosophy3.svg";
import closeIcon from "./images/closeIcon.svg";
import enlargeIcon from "./images/enlargeIcon.svg";

const { kakao } = window;
var map = "";

function MainPage() {
  const [isScrollMode, setIsCrollMode] = useState("full-page"); // FullPage 스크롤 방식
  const circleMenuList = document.getElementsByName("circleMenuList"); // 데스크탑 조직도 메뉴
  const isDesktop = useMediaQuery({
    // 데스크탑 여부
    query: "(min-width: 768px)",
  });
  const isMobile = useMediaQuery({
    // 모바일 여부
    query: "(max-width: 767px)",
  });

  const organizationMenuItems = [
    // 조직도 메뉴 아이템
    { name: "MRP HOLDINGS", img: mrpHoldingsGraphImg },
    { name: "MRP CONSULT", img: CONSULTGraphImg },
    { name: "MRP PARTNERS", img: PARTNERSGraphImg },
    { name: "MRP CORPERATION", img: "" },
    { name: "MRP SOFT", img: softGraphImg },
    { name: "MRP MEDIA", img: "" },
    { name: "MRP AD", img: "" },
    { name: "MRP F&B", img: "" },
    { name: "MRP E&M", img: EMGraphImg },
  ];

  const [selectedMenuItem, setSelectedMenuItem] = useState(null); // 선택된 조직도 메뉴
  const [selectedMenuItemIMG, setSelectedMenuItemIMG] = useState(null); // 선택된 조직도 메뉴 이미지

  //헤더 메뉴 영역들
  const fullPageRef = useRef(null); // 풀페이지
  const headerRef = useRef(null); // 헤더
  const [menuVisible, setMenuVisible] = useState(false); // 헤더 메뉴 보이기 여부
  const headerMBConRef = useRef(null); // 모바일 헤더 메뉴
  const phlisophyDOMRef = useRef(null);
  const strategyDOMRef = useRef(null);
  const organizationDOMRef = useRef(null);
  const contactUsDOMRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false); // 모바일 조직도 이미지 전체화면
  const [isOpen, setIsOpen] = useState(false); // 모바일 조직도 이미지 모달
  const organizationImage = useRef(null); // 조직도 이미지

  //지도 확대 클릭
  const clickMapZoom = (at) => {
    console.log(map instanceof kakao.maps.Map);

    if (at == "zoomIn") {
      map.setLevel(map.getLevel() - 1);
    } else if (at == "zoomOut") {
      map.setLevel(map.getLevel() + 1);
    } else if (at == "refresh") {
      initMap();
    }
  };

  //조직도 메뉴 동그란 메뉴 호버 시
  const organizationCircleMenuHoverOver = (event) => {
    event.currentTarget.childNodes[1].childNodes[0].style.fontWeight = "bold";
    event.currentTarget.childNodes[0].childNodes[0].style.backgroundColor = "#fff";
    event.currentTarget.childNodes[0].childNodes[0].style.boxShadow =
      "0px 0px 10px 10px rgba(255, 255, 255, 0.25)";
  };

  //조직도 메뉴 동그란 메뉴 호버 아웃 시
  const organizationCircleMenuHoverOut = (event) => {
    if (event.currentTarget.id.replaceAll(" ", "") == "false") {
      event.currentTarget.childNodes[1].childNodes[0].style.fontWeight = "";
      event.currentTarget.childNodes[0].childNodes[0].style.backgroundColor = "";
      event.currentTarget.childNodes[0].childNodes[0].style.boxShadow = "";
    }
  };

  //조직도 메뉴 동그란 모양 박스 눌렀을 경우
  const circleMenuClick = (event, at) => {
    if (at == "holdings" || at == "consult" || at == "partners" || at == "soft" || at == "em") {
      //모든 메뉴에 선택 효과 풀어주기
      for (var i = 0; i < circleMenuList.length; i++) {
        circleMenuList[i].childNodes[1].childNodes[0].style.fontWeight = "normal";
        circleMenuList[i].childNodes[0].childNodes[0].style.backgroundColor = "";
        circleMenuList[i].childNodes[0].childNodes[0].style.boxShadow = "";
        circleMenuList[i].id = "false";
        circleMenuList[i].childNodes[0].style.paddingTop = "10px";
        circleMenuList[i].childNodes[0].style.paddingBottom = "0px";
      }
      //모든 메뉴에 선택 효과 풀어주기

      //선택 효과 주기 시작
      event.currentTarget.id = "true";
      event.currentTarget.childNodes[1].childNodes[0].style.fontWeight = "bold";
      event.currentTarget.childNodes[0].childNodes[0].style.backgroundColor = "#fff";
      event.currentTarget.childNodes[0].childNodes[0].style.boxShadow =
        "0px 0px 10px 10px rgba(255, 255, 255, 0.25)";
      event.currentTarget.childNodes[0].style.paddingTop = "0px";
      event.currentTarget.childNodes[0].style.paddingBottom = "10px";

      //선택 효과 주기 끝
      if (at == "holdings") {
        organizationImage.current.src = mrpHoldingsGraphImg;
      } else if (at == "consult") {
        organizationImage.current.src = CONSULTGraphImg;
      } else if (at == "partners") {
        organizationImage.current.src = PARTNERSGraphImg;
      } else if (at == "corperation") {
      } else if (at == "soft") {
        organizationImage.current.src = softGraphImg;
      } else if (at == "ad") {
      } else if (at == "fb") {
      } else if (at == "em") {
        organizationImage.current.src = EMGraphImg;
      }
    }
  };

  //헤더 메뉴 클릭
  const onHeaderMenuClick = (at) => {
    if (fullPageRef.current) {
      fullPageRef.current.scrollToSlide(at);
      setMenuVisible(false);
    }
  };

  const card2Text =
    "유니버스 내에서 섹터별 저평가 종목을 중심으로\n압축된 포트폴리오를 구성하고\n국내외 환경분석과 수익의 기간구조, 외부 자료를 분석합니다.";
  const card1Text =
    "시가총액과 유동성, 시드라운드 혹은\n시리즈A 라운드의 투자자를 고려하여\n저평가 우량종목 위주의 선정을 거칩니다.";
  const strategyText =
    "행태 재무에서 가정하듯이 시장참여자들은 때때로 주어진 정보를 잘못 해석하고 비효율적인 의사결정을 하며 충동적인 시장행태를 보입니다. MRP파트너스는 이러한 원인으로 인한 시장의 이상현상에서 초과수익 기회를 포착하고 활용하기 위해 노력합니다.\n\n" +
    "MRP파트너스는 실증분석에 근거한 투자, 분산투자를 지향합니다. 경제논리가 뒷받침되는 실증분석에 근거한 투자전략을 추구하며 모든 투자전략을 실행하는데 있어 투자전략의 유효성 유지에 전제되는 가정 및 조건의 변화를 실증적으로 파악하여 반영할 수 있도록 최선의 노력을 경주합니다. 또한 집중투자 보다는 비체계적 위험을 제거한 분산투자가 위험대비 보상관점에서 우월하다는 신념 하에 개별 종목 보다는 위험 요인 특성을 반영한 포트폴리오 투자를 지향합니다.\n\n" +
    "MRP파트너스는 이러한 모토 하에 투자자의 자산 증식에 도움이 될 수 있는 장기적 관점에서의 투자 솔루션을 지속적으로 제공할 수 있도록 노력하겠습니다. MRP파트너스의 포트폴리오 매니저는 투자유니버스의 구성, 모델 포트폴리오 선정 및 실제 운용의 전과정에 참여하여 고객의 수익률을 안정화, 극대화 하고자 합니다.";
  const strategyMBHideText =
    "행태 재무에서 가정하듯이 시장참여자들은 때때로 주어진 정보를 잘못 해석하고 비효율적인 의사결정을 하며 충동적인 시장행태를 보입니다. MRP파트너스는 이러한 원인으로 인한 시장의 이상현상에서 초과수익 기회를 포착하고 활용하기 위해 노력합니다.";
  const checkBoxText =
    "구성원 모두 직접 참여하여\n결과를 최대한으로 이끌어\n낼 수 있도록 최선의 노력을 기울입니다.";
  const settintText = "수평적인 조직을 지향하며,\n자유로운 토론과\n창의적인 분위기를 추구합니다.";
  const dollarText =
    "자산을 선정함에 있어 장,단기적인 시장의\n성장 가능성을 내포하고 있는 산업군에\n단순 컨설팅 부터 기업인수합병,\n직접 투자까지 포괄적인 사업을 영위합니다.";

  // Philosophy 이미지 리스트
  const slickPhilosophyImages = [
    { image: philosophy1, text: dollarText },
    { image: philosophy2, text: settintText },
    { image: philosophy3, text: checkBoxText },
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Philosophy 현재 이미지 인덱스

  // Strategy 이미지 리스트
  const strategyImages = [
    { image: "card1Container", text: card1Text, title: "Stragegy1" },
    { image: "card2Container", text: card2Text, title: "Stragegy2" },
  ];
  const [currentStrategyImageIndex, setCurrentStrategyImageIndex] = useState(0); // Philosophy 현재 이미지 인덱스

  let prevScrollpos = window.pageYOffset; // 스크롤 방향을 판단하기 위한 변수
  window.onscroll = function () {
    // 스크롤 이벤트
    const currentScrollPos = window.pageYOffset; // 현재 스크롤 위치
    if (prevScrollpos > currentScrollPos) {
      // 스크롤 방향이 위쪽인 경우
      document.querySelector(".headerComponentAllContainer").classList.remove("hidden");
      document.querySelector(".headerComponentAllContainer").classList.add("visible");
    } else {
      // 스크롤 방향이 아래쪽인 경우
      document.querySelector(".headerComponentAllContainer").classList.remove("visible");
      document.querySelector(".headerComponentAllContainer").classList.add("hidden");
    }
    prevScrollpos = currentScrollPos;
  };

  //지도 초기화 시키기
  const initMap = () => {
    var container = document.getElementById("map");
    var options = {
      center: new kakao.maps.LatLng(37.52043773316489, 126.92929066752833),
      level: 3,
    };
    map = new kakao.maps.Map(container, options);
    // 지도를 클릭한 위치에 표출할 마커입니다
    var marker = new kakao.maps.Marker({
      // 지도 중심좌표에 마커를 생성합니다
      position: map.getCenter(),
    });
    marker.setMap(map);
    // 마커를 클릭했을 때 마커 위에 표시할 인포윈도우를 생성합니다
    var iwContent =
      '<div style="padding:10px; font-size:13px; font-family:Noto Sans KR; font-weight: bold; ">서울 영등포구 여의대방로 65길 24</div>'; // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
    // 인포윈도우를 생성합니다
    var infowindow = new kakao.maps.InfoWindow({
      content: iwContent,
    });
    infowindow.open(map, marker);
    // 마커에 클릭이벤트를 등록합니다
    kakao.maps.event.addListener(marker, "click", function () {
      // 마커 위에 인포윈도우를 표시합니다
      infowindow.open(map, marker);
    });
  };

  // 모바일 조직도 메뉴 버튼 클릭할 경우
  const handleDropBoxClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  // 모바일 조직도 메뉴 아이템을 클릭 할 경우
  const handleItemClick = (item, img) => {
    let removeSpaceName = item.replace(/\s+/g, '')
    if (removeSpaceName === "MRPHOLDINGS" ||
      removeSpaceName === "MRPCONSULT" ||
      removeSpaceName === "MRPPARTNERS" ||
      removeSpaceName === "MRPSOFT" ||
      removeSpaceName === "MRPE&M") {
      setSelectedMenuItem(item);
      setSelectedMenuItemIMG(img);
      setIsOpen(false);
    }

  };
  // 모바일 버전에서 헤더 메뉴 클릭시 해당 섹션으로 이동
  const clickMenuBtn = () => {
    setMenuVisible(!menuVisible);
  };

  const handleFullScreenClick = () => {
    setIsFullScreen(true);
    // 화면을 세로 모드로 고정
    // screen.orientation.lock("portrait");
  };
  const fullpageOptions = {
    scrollOverflow: false, // 스크롤 막기
  };

  // 모달이 보여질때 뒷배경 스크롤 막기
  const showModal = () => {
    setIsCrollMode("normal");
    // 헤더 올리기
    document.querySelector(".headerComponentAllContainer").classList.remove("visible");
    document.querySelector(".headerComponentAllContainer").classList.add("hidden");
    document.body.style.overflow = "hidden"; // body 요소에 overflow: hidden 스타일을 적용
  };

  // 모달이 닫힐 때 뒷배경 스크롤 풀기
  const hideModal = () => {
    setIsCrollMode("full-page");
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    //지도 설정 시작----------------------------------
    initMap();
    setSelectedMenuItem("MRP HOLDINGS");
    setSelectedMenuItemIMG(mrpHoldingsGraphImg);
    //지도 설정 끝----------------------------------
  }, []);

  // 모바일 버전에서 메뉴영역 외 마우스 클릭 시 메뉴 닫히게 하는 부분
  useEffect(() => {
    function handleClickOutside(event) {
      if (isMobile && headerMBConRef.current && !headerMBConRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuVisible]);

  // 모바알 버전에서 philosopy 이미지 fade in,out 효과
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % slickPhilosophyImages.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentImageIndex]);

  // 모바알 버전에서 philosopy 이미지 fade in,out 효과
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentStrategyImageIndex((currentStrategyImageIndex + 1) % strategyImages.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentStrategyImageIndex]);
  return (
    <div className="allContainer">
      <div className="headerComponentAllContainer" ref={headerRef}>
        <div className="headerContainer displayFlex blur">
          {/* 헤더 왼쪽 부분 시작 */}
          <div className="paddingLeft20" onClick={() => fullPageRef.current.scrollToSlide(0)}>
            <img className="headerLogoImg" src={mrpLogoBlack} />
          </div>
          {/* 헤더 왼쪽 부분 끝 */}
          {/* 헤더 오른쪽 부분 시작 */}
          {isDesktop ? (
            <div className="displayFlex alignItemsCenter">
              <div
                className="headerMenu marginRight50 cursorPointer"
                onClick={() => onHeaderMenuClick(1)}
              >
                <span className="menuFont">Philosophy</span>
              </div>
              <div
                className="headerMenu marginRight50 cursorPointer"
                onClick={() => onHeaderMenuClick(2)}
              >
                <span className="menuFont">Strategy</span>
              </div>
              <div
                className="headerMenu marginRight50 cursorPointer"
                onClick={() => onHeaderMenuClick(3)}
              >
                <span className="menuFont">Organization</span>
              </div>
              <div className="headerMenu cursorPointer" onClick={() => onHeaderMenuClick(4)}>
                <span className="menuFont">Contact us</span>
              </div>
            </div>
          ) : (
            <div className="menuBtnBox paddingRight15">
              <button className="menuBtn" onClick={() => clickMenuBtn()}>
                <img src={hamburgerMenu} alt="hamburgerMenu" />
              </button>
            </div>
          )}
          {/* 헤더 오른쪽 부분 끝 */}
        </div>
      </div>

      {/* 모바일 버전 헤더 메뉴 박스 */}
      {isMobile ? (
        <div className={`menuContainer ${menuVisible ? "show" : ""}`} ref={headerMBConRef}>
          <div className="menuSubContainer">
            <div className="menuCloseBox" onClick={() => setMenuVisible(false)}>
              <img src={xCloseBlack} alt="xCloseBlack" />
            </div>
            <div className="menuLogoBox">
              <img src={mrpLogoBlack} alt="mrpLogoBlack" />
            </div>
            <div className="headerMenu cursorPointer" onClick={() => onHeaderMenuClick(1)}>
              <span className="menuFont">Philosophy</span>
            </div>
            <div className="headerMenu cursorPointer" onClick={() => onHeaderMenuClick(2)}>
              <span className="menuFont">Strategy</span>
            </div>
            <div className="headerMenu cursorPointer" onClick={() => onHeaderMenuClick(3)}>
              <span className="menuFont">Organization</span>
            </div>
            <div className="headerMenu cursorPointer" onClick={() => onHeaderMenuClick(4)}>
              <span className="menuFont">Contact us</span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* 첫번째 블럭 시작 */}
      <FullPage ref={fullPageRef} scrollMode={isScrollMode} duration={800}>
        <Slide className="mainSectionContainer">
          <div className="blackFilter"></div>
          <div className="mainVideoContainer widthMatch heightMatch">
            <div className="heightMatch">
              <video
                src={mainVideo}
                type="video/mp4"
                autoPlay
                loop
                muted
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </div>
          <div className="mainMRPLogoContainer">
            <div className="displayFlex justifyContentCenter alignItemsCenter">
              <img src={mrpLogoWhite} alt="엠알피 이미지 (흰색)" width={"178px"} />
            </div>
          </div>
          <div className="scrollDownContainer" onClick={() => onHeaderMenuClick(1)}>
            <div className="displayFlex justifyContentCenter alignItemsCenter">
              <img id="downIcon" src={dowmGreyIcon} alt="" />
            </div>
            <div className="displayFlex justifyContentCenter alignItemsCenter">
              <img id="downIcon" src={downWhtieIcon} alt="" />
            </div>
            <div className="displayFlex justifyContentCenter alignItemsCenter">
              <span className="whiteTextColor fontNotoSansNormal ">scroll down</span>
            </div>
          </div>
        </Slide>
        {/* 첫번째 블럭 끝 */}

        <Slide className="philosophySectionContainer">
          <div
            className=" displayFlex justifyContentCenter alignItemsCenter heightMatch  "
            ref={phlisophyDOMRef}
          >
            <div className=" philosophySectionSubContainer  justifyContentCenter alignItemsCenter">
              <div className="textAlignCenter">
                <div>
                  <span className="fontNotoSansNormal whiteTextColor philosophyText">
                    2018년 설립된
                  </span>
                  <span className="fontNotoSansBold whiteTextColor philosophyText">
                    MRP파트너스
                  </span>
                  <span className="fontNotoSansNormal whiteTextColor philosophyText">는</span>
                </div>
                <div>
                  <span className="fontNotoSansNormal whiteTextColor philosophyText">
                    글로벌 시장에 유무형의 자산의
                  </span>
                </div>
                <div>
                  {" "}
                  <span className="fontNotoSansNormal whiteTextColor philosophyText">
                    현재와 미래에 투자하는 회사입니다.
                  </span>
                </div>
              </div>

              <div className="displayFlex alignItemsCenter justifyContentCenter marginTop80">
                <div className="alignItemsCenter">
                  {isDesktop ? (
                    <img className="phliosophyIcon" src={phliosophyIcon} />
                  ) : (
                    <img className="phliosophyIcon" src={phliosophyMBIcon} height={45} />
                  )}
                </div>
              </div>
              {isDesktop ? (
                <div className="displayFlex marginTop60">
                  <div className="childFlex1 textAlignCenter">
                    <img id="philosophyIcon" src={philosophy1} alt="달러 아이콘" />
                    <div className="marginTop28">
                      <span className="fontNotoSansNormal whiteTextColor whiteSpacePreLine fontSize14">
                        {dollarText}
                      </span>
                    </div>
                  </div>
                  <div className="childFlex1 textAlignCenter">
                    <img id="philosophyIcon" src={philosophy2} alt="설정 아이콘" />
                    <div className="marginTop28">
                      <span className="fontNotoSansNormal whiteTextColor whiteSpacePreLine fontSize14">
                        {settintText}
                      </span>
                    </div>
                  </div>
                  <div className="childFlex1 textAlignCenter">
                    <img id="philosophyIcon" src={philosophy3} alt="체크박스 아이콘" />
                    <div className="marginTop20">
                      <span className="fontNotoSansNormal whiteTextColor whiteSpacePreLine fontSize14">
                        {checkBoxText}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="philosophyContainer">
                  <div>
                    <div className="slider">
                      {slickPhilosophyImages.map((item, index) => (
                        <div
                          key={index}
                          className={`slide ${index === currentImageIndex ? "active" : ""}`}
                        >
                          <img id="philosophyIcon" src={item.image} alt="달러 아이콘" />
                          <div className="marginTop40">
                            <span className="fontNotoSansNormal whiteTextColor whiteSpacePreLine fontSize14">
                              {item.text}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Slide>
        {/* 두번째 블럭 끝 */}

        {/* 세번째 블럭 시작 */}
        <Slide className="strategySectionContainer">
          <div
            className=" displayFlex justifyContentCenter alignItemsCenter heightMatch"
            ref={strategyDOMRef}
          >
            <div className="strategySectionSubContainer">
              {/* 세번쨰 블럭 상단 텍스트 시작 */}
              {isDesktop ? (
                <div className="displayFlex justifyContentCenter">
                  <div className=" width1280 justifyContentCenter alignItemsCenter">
                    <div className="displayFlex textAlignCenter alignItemsCenter">
                      <div>
                        <img id="strategyLogoIcon" src={mrpLogoWhite} alt="" />
                      </div>
                      <div className="flexRightAlign width692  textAlignLeft">
                        <span className="fontNotoSansNormal whiteSpacePreLine whiteTextColor fontSize14">
                          {strategyText}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <div>
                      <div>
                        <img id="strategyLogoIcon" src={mrpLogoWhite} alt="" />
                      </div>
                      <div className="marginTop20">
                        <span className="fontNotoSansNormal fontWeightBold whiteSpacePreLine whiteTextColor fontSize16">
                          {"MRP파트너스는\n실증분석에 근거한 투자, 분산투자를 지향합니다."}
                        </span>
                      </div>
                      <div className="marginTop20">
                        <span className="fontNotoSansNormal whiteSpacePreLine whiteTextColor fontSize14">
                          {strategyMBHideText}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* 세번쨰 블럭 상단 텍스트 끝 */}

              {/* 세번쨰 블럭 중간 카드 시작 */}
              {isDesktop ? (
                <div className="cardContainer displayFlex justifyContentCenter">
                  <div className="displayFlex width1280 ">
                    <div className="displayFlex alignItemsCenter flexLeftAlign height250 card1Container childFlex1 ">
                      <div>
                        <div className="displayFlex alignItemsCenter  marginAll">
                          <div className="greyBar"></div>
                          <div className="fontSize12 whiteTextColor paddingLeft8">Strategy 01.</div>
                        </div>

                        <div className=" marginAll whiteSpacePreLine">
                          <span className="fontNotoSansBold whiteTextColor fontSize16">
                            {card1Text}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="displayFlex alignItemsCenter flexLeftAlign height250 card2Container childFlex1 marginLeft20">
                      <div>
                        <div className="displayFlex alignItemsCenter  marginAll">
                          <div className="greyBar"></div>
                          <div className="fontSize12 whiteTextColor paddingLeft8">Strategy 02.</div>
                        </div>

                        <div className=" marginAll whiteSpacePreLine">
                          <span className="fontNotoSansBold whiteTextColor fontSize16">
                            {card2Text}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="cardContainer ">
                  <div className="slider">
                    {strategyImages.map((item, index) => (
                      <div
                        key={index}
                        className={`slide ${index === currentStrategyImageIndex ? "active" : ""}`}
                      >
                        <div className={"strategyImageBox " + item.image}>
                          <div>
                            <div className="displayFlex alignItemsCenter  marginAll">
                              <div className="greyBar"></div>
                              <div className="fontSize12 whiteTextColor paddingLeft8">
                                {item.title}
                              </div>
                            </div>

                            <div className=" marginAll whiteSpacePreLine">
                              <span className="fontNotoSansBold whiteTextColor fontSize16">
                                {item.text}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {/* 세번쨰 블럭 중간 카드 시작 */}
          </div>
        </Slide>
        {/* 세번째 블럭 끝 */}

        {/* 네번째 블럭 시작 */}
        <Slide className="organizationContainer">
          <div
            className="displayFlex justifyContentCenter alignItemsCenter heightMatch"
            ref={organizationDOMRef}
          >
            <div className="organizationSubContainer">
              <div>
                <div className="displayFlex alignItemsCenter justifyContentCenter">
                  <div className=" alignItemsCenter">
                    {isDesktop ? (
                      <img className="phliosophyIcon" src={organizationIcon} />
                    ) : (
                      <img className="phliosophyIcon" src={organizationMBIcon} />
                    )}
                  </div>
                </div>

                {/* 모바일 조직도  메뉴 선택 */}
                {isDesktop ? (
                  ""
                ) : (
                  <div className="organizationMenuDropBoxContainer">
                    <div className="organizationMenuDropBoxSubContainer">
                      <div>
                        <span id="organizationMenuTitleText">MRP TEAM</span>
                      </div>
                      <div className="organizationMenuDropBoxHeader" onClick={handleDropBoxClick}>
                        <span>{selectedMenuItem}</span>
                        <img src={downVectorIcon} alt="downVectorIcon" />
                      </div>
                    </div>
                    <div className="organizationMenuDropBoxItemContainer">
                      {isOpen && (
                        <div className="organizationMenuDropBoxItemSubContainer">
                          {organizationMenuItems.map((item) => (
                            <div
                              className="organizationMenuDropBoxItem"
                              key={item.name}
                              onClick={() => handleItemClick(item.name, item.img)}
                            >
                              {item.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* 조직도 그래프 시작 */}
                {isDesktop ? (
                  <div>
                    <div className="displayFlex alignItemsCenter justifyContentCenter ">
                      <img
                        className="mrpHoldingsGraph"
                        src={mrpHoldingsGraphImg}
                        ref={organizationImage}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="mbMrpHoldingsGraphContainer"
                    onClick={() => handleFullScreenClick()}
                  >
                    <div className="mbMrpHoldingsGraphSubContainer">
                      <div className="mbEnLargeBox">
                        <img id="mbEnLargeIcon" src={enlargeIcon} alt="" />
                      </div>
                      <div className="heightMatch displayFlex alignItemsCenter justifyContentCenter ">
                        <img
                          className="mrpHoldingsGraph"
                          src={selectedMenuItemIMG}
                          ref={organizationImage}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* 조직도 그래프 끝 */}
                {/* 조직도 메뉴 변경 버튼 시작 */}
                {isDesktop ? (
                  <div className="organizationButtonMenuContainer displayFlex ">
                    <div
                      className="childFlex1 circleMenu"
                      name="circleMenuList"
                      id="true"
                      onClick={(event) => circleMenuClick(event, "holdings")}
                      onMouseOver={(event) => organizationCircleMenuHoverOver(event)}
                      onMouseOut={(event) => organizationCircleMenuHoverOut(event)}
                    >
                      <div
                        className="displayFlex alignItemsCenter justifyContentCenter"
                        style={{ paddingBottom: "10px" }} //바텀으로 바뀌고 마진 탑 가뀌가
                      >
                        <div
                          className="circle organiztionCircleMenuButton "
                          style={{
                            backgroundColor: "#fff",
                            boxShadow: "0px 0px 10px 10px rgba(255, 255, 255, 0.25)",
                          }}
                        ></div>
                      </div>

                      <div className="textAlignCenter" style={{ marginTop: "10px" }}>
                        <span
                          className="whiteTextColor fontSize14 fontNotoSansNormal"
                          style={{ fontWeight: "bold" }}
                        >
                          MRP HOLDINGS
                        </span>
                      </div>
                    </div>

                    <div
                      className="childFlex1 circleMenu"
                      name="circleMenuList"
                      id="false"
                      onClick={(event) => circleMenuClick(event, "consult")}
                      onMouseOver={(event) => organizationCircleMenuHoverOver(event)}
                      onMouseOut={(event) => organizationCircleMenuHoverOut(event)}
                    >
                      <div
                        className="displayFlex alignItemsCenter justifyContentCenter"
                        style={{ paddingTop: "10px" }}
                      >
                        <div
                          className="circle organiztionCircleMenuButton"
                          style={{ backgroundColor: "" }}
                        ></div>
                      </div>
                      <div className="textAlignCenter marginTop10">
                        <span
                          className="whiteTextColor fontSize14 fontNotoSansNormal"
                          style={{ fontWeight: "normal" }}
                        >
                          MRP CONSULT
                        </span>
                      </div>
                    </div>
                    <div
                      className="childFlex1 circleMenu"
                      name="circleMenuList"
                      id="false"
                      onClick={(event) => circleMenuClick(event, "partners")}
                      onMouseOver={(event) => organizationCircleMenuHoverOver(event)}
                      onMouseOut={(event) => organizationCircleMenuHoverOut(event)}
                    >
                      <div
                        className="displayFlex alignItemsCenter justifyContentCenter "
                        style={{ paddingTop: "10px" }}
                      >
                        <div
                          className="circle organiztionCircleMenuButton"
                          style={{ backgroundColor: "" }}
                        ></div>
                      </div>
                      <div className="textAlignCenter marginTop10">
                        <span
                          className="whiteTextColor fontSize14 fontNotoSansNormal"
                          style={{ fontWeight: "normal" }}
                        >
                          MRP PARTNERS
                        </span>
                      </div>
                    </div>
                    <div
                      className="childFlex1 circleMenu"
                      name="circleMenuList"
                      id="false"
                      onClick={(event) => circleMenuClick(event)}
                      onMouseOver={(event) => organizationCircleMenuHoverOver(event)}
                      onMouseOut={(event) => organizationCircleMenuHoverOut(event)}
                    >
                      <div
                        className="displayFlex alignItemsCenter justifyContentCenter"
                        style={{ paddingTop: "10px" }}
                      >
                        <div
                          className="circle organiztionCircleMenuButton"
                          style={{ backgroundColor: "" }}
                        ></div>
                      </div>
                      <div className="textAlignCenter marginTop10">
                        <span
                          className="whiteTextColor fontSize14 fontNotoSansNormal"
                          style={{ fontWeight: "normal" }}
                        >
                          MRP CORPERATION
                        </span>
                      </div>
                    </div>
                    <div
                      className="childFlex1 circleMenu"
                      name="circleMenuList"
                      id="false"
                      onClick={(event) => circleMenuClick(event, "soft")}
                      onMouseOver={(event) => organizationCircleMenuHoverOver(event)}
                      onMouseOut={(event) => organizationCircleMenuHoverOut(event)}
                    >
                      <div
                        className="displayFlex alignItemsCenter justifyContentCenter"
                        style={{ paddingTop: "10px" }}
                      >
                        <div
                          className="circle organiztionCircleMenuButton"
                          style={{ backgroundColor: "" }}
                        ></div>
                      </div>
                      <div className="textAlignCenter marginTop10">
                        <span
                          className="whiteTextColor fontSize14 fontNotoSansNormal"
                          style={{ fontWeight: "normal" }}
                        >
                          MRP SOFT
                        </span>
                      </div>
                    </div>
                    <div
                      className="childFlex1 circleMenu"
                      name="circleMenuList"
                      id="false"
                      onClick={(event) => circleMenuClick(event)}
                      onMouseOver={(event) => organizationCircleMenuHoverOver(event)}
                      onMouseOut={(event) => organizationCircleMenuHoverOut(event)}
                    >
                      <div
                        className="displayFlex alignItemsCenter justifyContentCenter"
                        style={{ paddingTop: "10px" }}
                      >
                        <div
                          className="circle organiztionCircleMenuButton"
                          style={{ backgroundColor: "" }}
                        ></div>
                      </div>
                      <div className="textAlignCenter marginTop10">
                        <span
                          className="whiteTextColor fontSize14 fontNotoSansNormal"
                          style={{ fontWeight: "normal" }}
                        >
                          MRP MEDIA
                        </span>
                      </div>
                    </div>
                    <div
                      className="childFlex1 circleMenu"
                      name="circleMenuList"
                      id="false"
                      onClick={(event) => circleMenuClick(event)}
                      onMouseOver={(event) => organizationCircleMenuHoverOver(event)}
                      onMouseOut={(event) => organizationCircleMenuHoverOut(event)}
                    >
                      <div className="displayFlex alignItemsCenter justifyContentCenter paddingTop10">
                        <div
                          className="circle organiztionCircleMenuButton"
                          style={{ backgroundColor: "" }}
                        ></div>
                      </div>
                      <div className="textAlignCenter marginTop10">
                        <span
                          className="whiteTextColor fontSize14 fontNotoSansNormal"
                          style={{ fontWeight: "normal" }}
                        >
                          MRP AD
                        </span>
                      </div>
                    </div>
                    <div
                      className="childFlex1 circleMenu"
                      name="circleMenuList"
                      id="false"
                      onClick={(event) => circleMenuClick(event)}
                      onMouseOver={(event) => organizationCircleMenuHoverOver(event)}
                      onMouseOut={(event) => organizationCircleMenuHoverOut(event)}
                    >
                      <div className="displayFlex alignItemsCenter justifyContentCenter paddingTop10">
                        <div
                          className="circle organiztionCircleMenuButton"
                          style={{ backgroundColor: "" }}
                        ></div>
                      </div>
                      <div className="textAlignCenter marginTop10">
                        <span
                          className="whiteTextColor fontSize14 fontNotoSansNormal"
                          style={{ fontWeight: "normal" }}
                        >
                          MRP F&B
                        </span>
                      </div>
                    </div>
                    <div
                      className="childFlex1 circleMenu"
                      name="circleMenuList"
                      id="false"
                      onClick={(event) => circleMenuClick(event, "em")}
                      onMouseOver={(event) => organizationCircleMenuHoverOver(event)}
                      onMouseOut={(event) => organizationCircleMenuHoverOut(event)}
                    >
                      <div className="displayFlex alignItemsCenter justifyContentCenter paddingTop10">
                        <div
                          className="circle organiztionCircleMenuButton"
                          style={{ backgroundColor: "" }}
                        ></div>
                      </div>
                      <div className="textAlignCenter marginTop10">
                        <span
                          className="whiteTextColor fontSize14 fontNotoSansNormal"
                          style={{ fontWeight: "normal" }}
                        >
                          MRP E&M
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Slide>
        {/* 네번째 블럭 끝 */}

        {/* 다섯번째(지도) 블럭 시작 */}
        <Slide className="locationAllContainer">
          <div className="locationAllSubContainer justifyContentCenter alignItemsCenter">
            <div className="widthMatch ">
              {isDesktop ? (
                <div className="displayFlex justifyContentCenter " ref={contactUsDOMRef}>
                  <div className=" width1280 justifyContentCenter alignItemsCenter ">
                    <div className="width1280 alignItemsCenter">
                      <img className="phliosophyIcon" src={contactUsIcon} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="displayFlex justifyContentCenter " ref={contactUsDOMRef}>
                  <div className="  justifyContentCenter alignItemsCenter ">
                    <div className=" alignItemsCenter">
                      <img className="phliosophyIcon" src={contactUsMBIcon} />
                    </div>
                  </div>
                </div>
              )}

              {/* 지도 부분 시작 */}
              <div className=" displayFlex justifyContentCenter marginTop32">
                <div className="mapContainer">
                  <div id="map"></div>
                  <div className="custom_typecontrol">
                    <div className="zoomContainer" onClick={() => clickMapZoom("zoomIn")}>
                      <img
                        src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_plus.png"
                        alt="확대"
                      />
                    </div>
                    <div className="zoomContainer" onClick={() => clickMapZoom("zoomOut")}>
                      <img
                        src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_minus.png"
                        alt="축소"
                      />
                    </div>
                    <div
                      className="zoomContainer marginTop10"
                      onClick={() => clickMapZoom("refresh")}
                    >
                      <img src={refreshIcon} alt="새로고침" />
                    </div>
                  </div>
                </div>
              </div>

              {/* 지도 부분 끝 */}
              <div className="locationContainer">
                <div className="locationSubContainer ">
                  <div className="">
                    <div className="justifyContentCenter alignItemsCenter  marginTop20">
                      {/* 오시는 길 시작 */}
                      <div className="">
                        <div>
                          <span className="fontNotoSansNormal fontSize14 whiteTextColor">
                            오시는 길
                          </span>
                        </div>
                        <div className="contactUsInfoBox">
                          <img className="locationImage" src={placeIcon} alt="위치 아이콘" />
                          <span className="locationInfoText">
                            영등포구 여의대방로 65길 24, 호성빌딩 8층
                          </span>
                        </div>
                      </div>

                      {/* 오시는 길 끝 */}
                      {/* 전화번호 시작 */}
                      <div className="displayFlex marginTop40">
                        {/* 집전화 시작*/}
                        <div className="phoneNumberContainer displayFlex alignItemsCenter ">
                          <img id="numberIcon" src={phoneIcon} alt="전화기 아이콘" />
                          <div className="marginLeft10">
                            <span className="fontNotoSansNormal fontSize14 whiteTextColor">
                              <p>
                                <a href="tel:02-2039-5838">02-2039-5838</a>
                              </p>
                            </span>
                          </div>
                        </div>
                        {/* 집전화 끝 */}
                        {/* 팩스번호 시작 */}
                        <div className="displayFlex alignItemsCenter marginLeft10">
                          <img id="numberIcon" src={faxIcon} alt="팩스 아이콘" />
                          <div className="marginLeft10">
                            <span className="fontNotoSansNormal fontSize14 whiteTextColor">
                              <p>
                                <a href="tel:02-2039-5838">02-2039-5838</a>
                              </p>
                            </span>
                          </div>
                        </div>
                        {/* 팩스번호 끝 */}
                      </div>
                      {/* 전화번호 끝 */}
                    </div>
                  </div>
                </div>
              </div>
              {/* footer 시작 */}
              <div className="footerContainer displayFlex justifyContentCenter">
                <div className="footerSubContainer displayFlex width1280 alignItemsCenter flexBottomAlign">
                  <div>
                    <div>
                      <span className="footerText">회사명 : MRP PARTNER</span>
                    </div>
                    <div>
                      <span className="footerText">
                        주소 : 서울특별시 영등포구 여의대방로 65길 24, 호성빌딩
                        8층&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {isDesktop ? "전화번호 : 02-2039-5838" : "\n전화번호 : 02-2039-5838"}
                      </span>
                    </div>
                    <div>
                      <span className="footerText">
                        Copyright ⓒ MRP PARTNERS All Rights Reserved.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* footer 끝 */}
            </div>
          </div>
        </Slide>
        {/* 다섯번째(지도) 블럭 끝 */}
      </FullPage>

      <Modal
        isOpen={isFullScreen}
        onAfterOpen={showModal}
        onAfterClose={hideModal}
        overlayClassName="editStateModalOverlay"
        className="editStateModal"
        shouldCloseOnOverlayClick={false} // 바깥 영역 클릭 시 모달이 닫히지 않도록 설정합니다.
      >
        <img id="closeIconImg" src={closeIcon} alt="" onClick={() => setIsFullScreen(false)} />

        <TransformWrapper>
          <TransformComponent>
            <div className="fullScreenContainer">
              <div className="fullScreenSubContainer">
                <img className="fullScreenImage" src={selectedMenuItemIMG} />
              </div>
            </div>
          </TransformComponent>
        </TransformWrapper>
      </Modal>
    </div>
  );
}

export default MainPage;
